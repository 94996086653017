import React from 'react'
import SEO from '../components/seo'

const IndexPage = () => (
  <main
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh'
    }}
  >
    <SEO title="Please Login" />
    <h1>
      <span role="img" aria-label="Stop!">
        ✋
      </span>{' '}
      Please login
    </h1>
  </main>
)

export default IndexPage
